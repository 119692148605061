import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <p className={styles.footertext}>Succinct - Team team - BCIT Winter Hacks 2021</p>
    </div>
  )
}

export default Footer;